<template>
    <section class="invoice-preview-wrapper">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="12" md="8">
                    <b-card no-body class="invoice-actions">
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="12">
                                    <b-form-group label="Buscar por" label-cols="12" label-class="font-weight-bolder"
                                        label-align="left">
                                        <b-form-radio-group v-model="filter.option" id="radio-group-1" :options="optionsFilters"
                                            :reduce="option => option.value" name="radio-options" class="groupselect"
                                            ></b-form-radio-group>
                                    </b-form-group>
                                </b-col>

                                <!-- <b-col cols="12" md="8">
                                    <b-form-group label-cols="12" label-class="font-weight-bolder mt-2">
                                        <b-form-checkbox v-model="incluirTickets" name="check-button" switch inline class="font-weight-bolder">
                                            Incluir Salidas con Ticket
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col> -->

                                <b-col cols="12" md="2">
                                    <b-form-group label="Fecha Inicio" label-for="date_start">
                                        <flat-pickr v-model="filter.date_start" id="date_start"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="2">
                                    <b-form-group label="Fecha Fin" label-for="date_start">
                                        <flat-pickr v-model="filter.date_end" id="date_end"
                                            class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                            placeholder="DD/MM/YYYY" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="3">
                                    <b-form-group label="Estatus" label-for="status">
                                        <b-form-select size="sm" v-model="filter.status" id="status"
                                            :options="['TODAS', 'ABIERTA', 'EN RUTA', 'CERRADA', 'CLIENTE PASA', 'CANCELADA']"
                                            class="select-size-sm" placeholder="Selecciona una opción" />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="4">
                                    <b-form-group label="Almacen" label-for="warehouse" label-class="font-weight-bold">
                                        <v-select id="warehouse" v-model="wareHouse_Id" :options="wareHouses"
                                            label="nombre" :reduce="option => option.almacen_Id" :clearable="false"
                                            :searchable="false" class="select-size-sm"
                                            placeholder="Selecciona una opción">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="9" class="mt-0">
                                    <b-input-group>
                                        <b-form-input v-model="filter.searchText" size="sm" placeholder="Filtrar por folio, cliente, origen, portador, asignado, almacen" />
                                    </b-input-group>
                                </b-col>

                                <b-col cols="12" md="3" class="mt-0">
                                    <b-button :disabled="loading" @click="getDeliveryRoutings(1)" size="sm" variant="primary" class="mr-1">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12">
                    <b-card no-body class="mb-0">
                        <div class="table-responsive">
                            <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${totalRecordsRouting > 10 ? 'b-table-sticky-header' : ''}`">
                                <table class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 10px!important;" class="text-center">% Entrega</th>
                                            <th style="font-size: 10px!important;" class="text-center">Folio</th>
                                            <th style="font-size: 10px!important;" class="text-center">Fecha Factura</th>
                                            <th style="font-size: 10px!important;" v-if="showColumnOrigin" class="text-center">Origen</th>
                                            <th style="font-size: 10px!important;" class="text-center">Cliente</th>
                                            <th style="font-size: 10px!important;" class="text-center">Almacen</th>
                                            <th style="font-size: 10px!important;" class="text-center">Portador</th>
                                            <th style="font-size: 10px!important;" class="text-center">Asignado</th>
                                            <th style="font-size: 10px!important;" class="text-center">Saldo</th>
                                            <th style="font-size: 10px!important;" class="text-center">Estatus</th>
                                            <th style="font-size: 10px!important;" class="text-center">Documento Recibido</th>
                                            <th style="font-size: 10px!important;" class="text-center">Impreso</th>
                                            <th style="font-size: 10px!important;" class="text-center">Instrucciones</th>
                                            <th style="font-size: 10px!important;" class="text-center">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in routings" :key="index" @click="selectRow(item, index)"
                                            :class="{ 
                                                'selected-row': isSelected(index),
                                            }">
                                            <td style="font-size: 10px!important;">
                                                <b-badge pill :variant="setColorClass(item)"><small>{{ setDeliveryPercent(item) }}%</small></b-badge>
                                            </td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.folio }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ formatDate(item.fecha_Factura) }}</td>
                                            <td style="font-size: 10px!important;" v-if="showColumnOrigin" class="text-capitalize">{{ item.nombre_Sucursal }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.nombre_Cliente }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.nombre_Almacen }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.portador }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.asignado }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ formatCurrency(item.saldo) }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize">{{ item.estatus }}</td>
                                            <td style="font-size: 10px!important;" class="text-capitalize text-center">
                                                <b-badge :variant="item.documento_Recibido ? 'success' : 'danger'" pill>
                                                    <small>{{ item.documento_Recibido ? 'Si' : 'No' }}</small>
                                                </b-badge>
                                            </td>

                                            <td style="font-size: 10px!important;" class="text-capitalize text-center">
                                                <b-badge :variant="item.impreso ? 'success' : 'danger'" pill>
                                                    <small>{{ item.impreso ? 'Si' : 'No' }}</small>
                                                </b-badge>
                                            </td>
                                            <td style="font-size: 10px!important;" class="text-center">
                                                <b-badge :variant="diasPlazoVariant(item)" pill>
                                                    <small>{{ diasPlazoLabel(item) }}</small>
                                                </b-badge>
                                            </td>
                                            <td style="font-size: 10px!important;" class="text-center">
                                                <b-button v-if="canReceiveSignedDocument && canReceiveDocument(item)"
                                                    size="sm"
                                                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="btn-icon"
                                                    variant="primary" @click="markAsReceivedDocument(item)"
                                                    v-b-tooltip.hover.top="`Documento recibido`">
                                                    <feather-icon icon="CheckSquareIcon" />
                                                </b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- Pagination -->
                        <div v-if="routings.length > 0" class="mx-2 mb-2">
                            <b-row>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                    <span class="text-muted">{{ resolvePaginationTranslate(dataMetaRouting) }}</span>
                                </b-col>
                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <div class="mt-2 demo-spacing-0">
                                        <b-pagination v-model="currentPageRouting" :total-rows="totalRecordsRouting" :per-page="pageSizeRouting"/>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

        </b-overlay>
    </section>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
    BPagination,
    BTooltip, 
    VBTooltip,
    BFormCheckbox,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';
import moment from 'moment';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

export default {
    name: "RoutingLogs",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormGroup,
        BFormSelect,
        BBadge,
        BFormRadioGroup,
        BPagination,
        BTooltip, 
        VBTooltip,
        BFormCheckbox,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            
        }
    },
    computed: {
        canReceiveSignedDocument() {
            //Permitir recibir el documento firmada en oficina en el enrutamiento de facturas
            return this.canAccess(37)
        },
        ...mapGetters({
          getWareHouses: 'auth/getWareHouses',
          logisticSetting: 'auth/getLogisticSetting',
        }),
        showColumnOrigin() {
            return this.findSetting('ACTIVAR_COLUMNA_ORIGEN_LOGISTICA') || false
        },
        dataMetaRouting() {
            const localItemsCount = _.size(this.routings);
            return {
                from:
                    this.pageSizeRouting * (this.currentPageRouting - 1) +
                    (localItemsCount ? 1 : 0),
                to: this.pageSizeRouting * (this.currentPageRouting - 1) + localItemsCount,
                of: this.totalRecordsRouting,
            };
        },
    },
    created() {
        this.loadWareHouses();
    },
    methods: {
        canReceiveDocument(item) {
            if (item && item.estatus == 'SALIDA TICKET' && item.tipo_Docto == 'F' && !item.documento_Recibido) {
                return true
            } else if (!item.documento_Recibido && item.estatus == 'CERRADA') {
                return true
            }
            return false
        },
        loadWareHouses() {
            // Obtener la lista de almacenes del store
            const wareHousesFromStore = this.getWareHouses;

            // Crear el nuevo objeto de almacén
            const allWareHousesOption = {
                almacen_Id: 0,
                nombre: 'TODAS'
            };

            // Agregar el nuevo objeto al inicio de la lista
            this.wareHouses = [allWareHousesOption, ...wareHousesFromStore];
        },
        markAsReceivedDocument(item) {
            this.$swal.fire({
                title: `Documento Recibido`,
                html: `¿Está seguro de que desea marcar el documento <b>${item.folio}</b> como recibido?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        this.loading = true
                        const { data } = await DeliveryRoutesResource.markAsReceivedDocument(item.id)
                        this.loading = false
                        if (data.isSuccesful) {
                            this.success(data.message)
                            
                            this.routings = this.routings.map(routing => {
                                if (routing.id === item.id) {
                                    routing.documento_Recibido = true
                                }
                                return routing
                            })

                        } else {
                            this.danger(data.message)
                        }
                    }catch(e) {
                        this.loading = false
                        this.handleResponseErrors(e)
                    }
                }
            })
        },
    },
    watch: {
        currentPageRouting(value) {
            this.getDeliveryRoutings(value)
        },
        pageSizeRouting(value) {
            this.getDeliveryRoutings(this.currentPageRouting)
        },
    },
    setup() {

        const loading = ref(false)
        const search = ref(null)
        const pageSizeRouting = ref(30)
        const totalPagesRouting = ref(0)
        const totalRecordsRouting = ref(0)
        const currentPageRouting = ref(1)
        const routings = ref([])
        const searchText = ref('')
        const incluirTickets = ref(false)

        const filter = ref({
            date_start: moment().add(-3, 'month').startOf('month').format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            status: 'CERRADA', //'ABIERTA,EN RUTA,CERRADA',
            chofer_Id: null,
            camion_Id: null,
            option: 'fecha_factura',
        })
        const wareHouses = ref([])
        const wareHouse_Id = ref(0)
        const optionsFilters = ref([
            { value: 'fecha_factura', text: 'Fecha Factura' },
            { value: 'fecha_ruta', text: 'Fecha Ruta' },
        ])

        const getDeliveryRoutings = async (pageNumber = 1)  =>{

            routings.value = []
            let query = {
                fechaInicio: filter.value.date_start,
                fechaFin: filter.value.date_end,
                numeroPagina: pageNumber,
                tamanoPagina: pageSizeRouting.value,
                search: filter.value.searchText || '',  
                almacenId: wareHouse_Id.value,
                status: filter.value.status === 'TODAS' ? 'ABIERTA, EN RUTA, CLIENTE PASA, CERRADA, CANCELADA, SALIDA TICKET' : filter.value.status,
                esFechaRuta: filter.value.option === 'fecha_ruta',
            }

            if (incluirTickets.value) {
                query.incluirTickets = 'S'
            }

            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getAllByFilters(query)
                loading.value = false
                if(data.isSuccesful) {
                    routings.value = 
                        data.data.map(
                            item => ({...item, 
                            saldo: item.docto.doctosPVSaldo[0].saldo_Cargo, 
                            nombre_Sucursal: item.docto.nombre_Sucursal,
                            portador: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                            asignado: item.webOperadores ? item.webOperadores.nombre + ' ' + item.webOperadores.apellidos : null,
                        }))
                        
                    totalPagesRouting.value = data.totalPages;
                    totalRecordsRouting.value = data.totalRecords;
                    currentPageRouting.value = data.currentPage;

                } else {
                    helper.danger(data.message)
                }
            }catch(e) {
                loading.value = false
                helper.handleResponseErrors(e)
            }finally {
                loading.value = false
            }
        }

        const diasPlazoLabel = (item) => {
            if (item.docto && item.docto.doctosPVSaldo && item.docto.doctosPVSaldo.length > 0 && item.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'PAGADA'
            }

            if (item.cliente.dias_Plazo == 0) {
                return 'P.A.R'
            }
            if (item.cliente.dias_Plazo > 0) {
                return 'CLIENTE FIRMA'
            }
        }

        const diasPlazoVariant = (item) => {
            if (item.docto && item.docto.doctosPVSaldo && item.docto.doctosPVSaldo.length > 0 && item.docto.doctosPVSaldo[0].saldo_Cargo == 0) {
                return 'success'
            }

            if (item.cliente.dias_Plazo == 0) {
                return 'danger'
            }

            if (item.cliente.dias_Plazo > 0) {
                return 'warning'
            }
        }

        const selectRow = (item, index) => {
           
        }

        const isSelected = (index) => {
           
        }

        watch(incluirTickets, (value) => {
            if (value) {
                filter.value.status =  'TODAS'
                wareHouse_Id.value = 0
            } else {
                filter.value.status =  'CERRADA'
            }
        })


        return {
            search,
            loading,  
            filter,
            wareHouses,
            wareHouse_Id, 
            routings,
            searchText,
            pageSizeRouting,
            totalPagesRouting,
            totalRecordsRouting,
            currentPageRouting,
            optionsFilters,
            incluirTickets,

            
            //methods
            diasPlazoLabel,
            diasPlazoVariant,
            selectRow,
            isSelected,
            getDeliveryRoutings,
        };
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
        font-size: 8pt !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {



        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>


<style>

.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px;
    /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px !important;
    /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}

.b-table-sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 200px)!important;
}
</style>